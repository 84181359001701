.wrapper {
  max-width: 1140px;
  margin: 0 auto;
  text-align: left;
  padding: 0 15px;
}

.top ul {
  display: flex;
}

.top ul li {
  margin-right: 20px;
}

.top ul li a {
  color: #5a5a5a;
}

.bottom {
  color: #a2a2a2;
}

.link {
  float: right;
  top: 0;
  display: flex;
}

.link a {
  margin-right: 14px;
}

.family-site li {
  padding: 5px;
}

.family-site a {
  color: #8c8c8c;
}

.company-address-container {
  display: flex;
}

.company-address-container .divider {
  padding: 0 10px;
}

@media (max-width: 1140px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .top {
    align-items: center;
  }

  .top ul {
    margin-bottom: 0;
  }

  .top ul li {
    margin-top: 20px;
    margin-right: 0;
  }

  .top ul li:nth-child(2) {
    margin: 20px;
  }

  .link {
    float: none;
    top: 0;
    display: flex;
  }

  .company-address-container {
    flex-direction: column;
  }

  .company-address-container .divider {
    display: none;
  }
}
