.wrapper {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
}

.logo {
  float: left;
  margin: 16px 24px 16px 0;
  display: flex;
  align-items: center;
}

.pc-menu {
  display: block;
}

.mobile-menu {
  display: none;
}

.locale-selector {
  float: right;
}

.mobile-menu-modal {
  position: fixed;
  height: calc(100% - 64px);
  width: 100%;
  top: 64px;
  left: 0;
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
  background-color: #ffffff;
  padding: 30px;
  z-index: 99999;
  overflow-y: scroll;
}

.mobile-menu-modal.hide {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.inner {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.mobile-menu-modal ul {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 30px;
  font-weight: 700;
}

.mobile-menu-modal li {
  padding: 20px 0;
}

.mobile-menu-modal li a {
  color: #000000;
}

.mobile-menu-modal .locale-selector {
  width: 100%;
  text-align: end;
  font-size: 18px;
}

@media (max-width: 1140px) {
  .pc-menu {
    display: none;
  }

  .mobile-menu-modal {
    display: block;
  }

  .mobile-menu {
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
  }
}
