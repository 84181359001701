@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.5715;
}

a {
  color: inherit;
  text-decoration: none;
}

a:active {
  color: #ff66af;
}

* {
  box-sizing: border-box;
  word-break: keep-all;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  padding: 0;
  margin: 0;
}

li,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* custom antd */
.ant-layout-header {
  background: #ffffff !important;
  padding: 0 20px !important;
  z-index: 10;
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-layout {
  background: #ffffff !important;
}

.ant-menu-horizontal {
  border: 0 !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
}

.history__antd__collapse .ant-collapse-header,
.history__antd__collapse_a .ant-collapse-header {
  align-items: center !important;
}

.history__antd__collapse .ant-collapse-item {
  border-bottom: 1px solid #cfcfcf !important;
}

.ant-divider-horizontal {
  margin: 48px 0 60px 0 !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-tab {
  font-size: 20px !important;
}

.ant-input-affix-wrapper {
  border: none !important;
  border-bottom: 1px solid #cfcfcf !important;
}

.swiper.partnership-swiper {
  overflow: visible !important;
}

.partnership-swiper .swiper-button-next,
.partnership-swiper .swiper-button-prev {
  color: #ffffff !important;
}

.partnership-swiper .swiper-button-prev {
  left: 280px !important;
}

.partnership-swiper .swiper-button-next {
  right: 280px !important;
}

.partnership-swiper .swiper-slide.swiper-slide-active {
  transform: scaleX(1.1) scaleY(1.1) !important;
}

.disable-pc-break {
  display: none;
}

.ant-layout-footer {
  padding: 24px 30px !important;
  background-color: #f0f2f5 !important;
}

.ant-layout-footer ul {
  margin-bottom: 1em;
}

.ant-btn-primary a:active,
.ant-btn-primary a:hover,
.cta-btn:hover,
.cta-btn:active {
  color: #ffffff !important;
}

@media screen and (max-width: 1440px) {
  .partnership-swiper .swiper-button-prev {
    left: 224px !important;
  }

  .partnership-swiper .swiper-button-next {
    right: 224px !important;
  }
}

@media screen and (max-width: 1024px) {
  .partnership-swiper .swiper-button-prev {
    left: 160px !important;
  }

  .partnership-swiper .swiper-button-next {
    right: 160px !important;
  }

  .disable-pc-break {
    display: block;
  }

  .disable-tablet-break {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .partnership-swiper .swiper-slide.swiper-slide-active {
    transform: none !important;
  }

  .partnership-swiper .swiper-button-prev {
    display: none;
  }

  .partnership-swiper .swiper-button-next {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .ant-tabs > .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px !important;
  }

  .ant-divider-horizontal {
    margin: 36px 0 25px 0 !important;
  }

  .disable-mobile-break {
    display: none;
  }

  .ant-layout-footer {
    padding: 24px 15px !important;
  }
}

.LocaleSelector_wrapper__wYNhs {
  line-height: 61px;
  color: #2e2e2e;
}

.LocaleSelector_wrapper__wYNhs button {
  margin: 0;
  color: #dddddd;
  background-color: inherit;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.LocaleSelector_wrapper__wYNhs button.LocaleSelector_active__1t_YE {
  color: #2e2e2e;
}

.LocaleSelector_divider__960gK {
  padding: 0 10px;
}
.DefaultMenu_wrapper__O9gO6 {
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  height: 100%;
}

.DefaultMenu_logo__iQRAu {
  float: left;
  margin: 16px 24px 16px 0;
  display: flex;
  align-items: center;
}

.DefaultMenu_pc-menu__SLfsW {
  display: block;
}

.DefaultMenu_mobile-menu__HUac5 {
  display: none;
}

.DefaultMenu_locale-selector__nyBwh {
  float: right;
}

.DefaultMenu_mobile-menu-modal__SEaJA {
  position: fixed;
  height: calc(100% - 64px);
  width: 100%;
  top: 64px;
  left: 0;
  transform: translateX(0%);
  transition: transform 0.3s ease-in-out;
  background-color: #ffffff;
  padding: 30px;
  z-index: 99999;
  overflow-y: scroll;
}

.DefaultMenu_mobile-menu-modal__SEaJA.DefaultMenu_hide__O7hv1 {
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.DefaultMenu_inner__7cPGD {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.DefaultMenu_mobile-menu-modal__SEaJA ul {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  font-size: 30px;
  font-weight: 700;
}

.DefaultMenu_mobile-menu-modal__SEaJA li {
  padding: 20px 0;
}

.DefaultMenu_mobile-menu-modal__SEaJA li a {
  color: #000000;
}

.DefaultMenu_mobile-menu-modal__SEaJA .DefaultMenu_locale-selector__nyBwh {
  width: 100%;
  text-align: end;
  font-size: 18px;
}

@media (max-width: 1140px) {
  .DefaultMenu_pc-menu__SLfsW {
    display: none;
  }

  .DefaultMenu_mobile-menu-modal__SEaJA {
    display: block;
  }

  .DefaultMenu_mobile-menu__HUac5 {
    display: block;
    position: absolute;
    top: 0px;
    right: 20px;
    z-index: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 60px;
  }
}

.FooterContent_wrapper__hREb1 {
  max-width: 1140px;
  margin: 0 auto;
  text-align: left;
  padding: 0 15px;
}

.FooterContent_top__LOc3h ul {
  display: flex;
}

.FooterContent_top__LOc3h ul li {
  margin-right: 20px;
}

.FooterContent_top__LOc3h ul li a {
  color: #5a5a5a;
}

.FooterContent_bottom__Is563 {
  color: #a2a2a2;
}

.FooterContent_link___l7Gj {
  float: right;
  top: 0;
  display: flex;
}

.FooterContent_link___l7Gj a {
  margin-right: 14px;
}

.FooterContent_family-site__GnDEU li {
  padding: 5px;
}

.FooterContent_family-site__GnDEU a {
  color: #8c8c8c;
}

.FooterContent_company-address-container__WZaal {
  display: flex;
}

.FooterContent_company-address-container__WZaal .FooterContent_divider__tIV2_ {
  padding: 0 10px;
}

@media (max-width: 1140px) {
  .FooterContent_wrapper__hREb1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .FooterContent_top__LOc3h {
    align-items: center;
  }

  .FooterContent_top__LOc3h ul {
    margin-bottom: 0;
  }

  .FooterContent_top__LOc3h ul li {
    margin-top: 20px;
    margin-right: 0;
  }

  .FooterContent_top__LOc3h ul li:nth-child(2) {
    margin: 20px;
  }

  .FooterContent_link___l7Gj {
    float: none;
    top: 0;
    display: flex;
  }

  .FooterContent_company-address-container__WZaal {
    flex-direction: column;
  }

  .FooterContent_company-address-container__WZaal .FooterContent_divider__tIV2_ {
    display: none;
  }
}

