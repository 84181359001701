.wrapper {
  line-height: 61px;
  color: #2e2e2e;
}

.wrapper button {
  margin: 0;
  color: #dddddd;
  background-color: inherit;
  border: 0;
  padding: 0;
  cursor: pointer;
}

.wrapper button.active {
  color: #2e2e2e;
}

.divider {
  padding: 0 10px;
}